
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import QuestionTemp from "./components/question-temp.vue";
import { isPositiveInteger } from "@/utils/verify";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";
const base = namespace("base");
const app = namespace("app");
const learning = namespace("learning");

@Component({
  components: { FileUpload, QuestionTemp }
})
export default class ExamEdit extends Vue {
  @base.Action getDictionaryList;
  @app.Mutation setPBPX;
  @learning.Action saveExamPaper;
  @learning.Action queryExamPaperDetail;
  @learning.Action queryEnableLevelList;
  @learning.Mutation setQuestionTypeList;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  get breadData() {
    return [
      { name: t("v210831.examination-certification"), path: "/learning/exam/" },
      { name: this.isAdd ? t("v210831.new-exam") : t("v210831.editing-test") }
    ];
  }
  pcPermList: any = [];
  appPermList: any = [];
  defaultMiniImageList: any = [];
  examPaperData: any = {
    answerTime: "",
    examBeginTime: "",
    examEndTime: "",
    examPaperTempName: "",
    minImage: "",
    passScore: "",
    totalScore: "",
    levelCode: "", // 认证级别
    contentDetail: []
  };
  showMobileUsedTips: boolean = false;
  options: any[] = [];
  showAreaSelect: boolean = false; //控制地区信息set完成后，显示组件
  loadEnd: boolean = false;
  certLevelList: any = [];
  definitionQuestionData: any = {
    questionAnswerMinSize: "",
    questionIndex: "1",
    questionOption: [],
    questionScore: "",
    questionTitle: "",
    questionType: "",
    containAttachment: "0", // 必须上传影像
    questionCompulsory: "1", // 必答题
    isMustAnswer: true,
    isContainAttachment: false
  };
  get id() {
    return this.$route.params.id;
  }
  get isAdd() {
    return this.$route.name === "learningExamAdd";
  }
  examPaperRules = {
    examPaperTempName: [{ required: true, message: t("v210831.please-enter-test-name"), trigger: "blur" }],
    levelCode: [{ required: true, message: t("v210831.please-select-the-level"), trigger: "change" }],
    examBeginTime: [{ required: true, message: t("v210831.please-select-the-time"), trigger: "change" }],
    passScore: [
      { required: true, message: t("v210831.please-enter-a-line"), trigger: "blur" },
      { validator: this.checkPassScore, trigger: "blur" }
    ],
    answerTime: [
      { required: true, message: t("v210831.please-enter-the-question-time"), trigger: "blur" },
      { validator: this.checkAnswerTime, trigger: "blur" }
    ],
    examEndTime: { required: true, message: t("v210831.please-enter-the-end-time"), trigger: "blur" },
    minImage: { required: true, message: t("v210831.please-upload-a-img"), trigger: "blur" }
  };
  checkPassScore(rule, value, callback) {
    if (!isPositiveInteger(value)) {
      callback(new Error(t("v210831.please-enter-a-integer")));
    } else {
      callback();
    }
  }
  checkAnswerTime(rule, value, callback) {
    if (!isPositiveInteger(value)) {
      callback(new Error(t("v210831.please-enter-a-integer")));
    } else {
      callback();
    }
  }
  created() {
    this.setPBPX(52);
    this.init();
  }
  submitForm() {
    (this.$refs["examPaperForm"] as any).validate(valid => {
      if (valid && this.checkData()) {
        if (this.$route.query.id) {
          this.examPaperData.examPaperTempCode = "";
        }
        this.excuSaveExamPaper();
      }
    });
  }
  handleComputedTotalScore() {
    let questionList = this.examPaperData.contentDetail || [];
    this.examPaperData.totalScore = questionList.reduce((prev, next) => {
      return prev + Number(next.questionScore);
    }, 0);
  }
  handleComputedEndTime() {
    if (this.examPaperData.examBeginTime) {
      let endTime = dayjs(this.examPaperData.examBeginTime).add(Number(this.examPaperData.answerTime), "m");
      this.examPaperData.examEndTime = endTime.format("YYYYMMDDHHmmss");
    }
  }
  handleMiniImageUploadSuccess() {
    (this.$refs.examPaperForm as any).validateField("minImage");
  }
  checkData() {
    let questionList = this.examPaperData.contentDetail;
    if (dayjs(this.examPaperData.examBeginTime).isBefore(dayjs())) {
      this.$message.error(t("v210831.the-test-start-not-now"));
      return false;
    }
    if (!questionList || questionList.length < 1) {
      this.$message.error(t("v210831.please-add-questions"));
      return false;
    }
    for (let i = 0; i < questionList.length; i++) {
      let questionData = questionList[i];
      if (!questionData.questionTitle) {
        this.$message.error(t("v210831.please-enter-the-question-title"));
        return false;
      }
      if (questionData.questionType === "1" || questionData.questionType === "2") {
        if (!questionData.questionOption || questionData.questionOption.length < 1) {
          this.$message.error(t("v210831.please-add-at-one-select"));
          return false;
        }
        for (let m = 0; m < questionData.questionOption.length; m++) {
          let option = questionData.questionOption[m];
          if (!option.optionValue) {
            this.$message.error(t("v210831.please-specify-a-describe"));
            return false;
          }
        }
      }
      if (questionData.questionType === "1") {
        let yesList = questionData.questionOption.filter(item => item.optionType === "1");
        if (yesList.length < 1) {
          this.$message.error(t("v210831.please-specify-a-answer"));
          return false;
        }
        if (yesList.length > 1) {
          this.$message.error(t("v210831.only-one-correct"));
          return false;
        }
      }
      if (questionData.questionType === "2") {
        let yesList = questionData.questionOption.filter(item => item.optionType === "1");
        if (yesList.length < 2) {
          this.$message.error(t("v210831.please-specify-at-two"));
          return false;
        }
      }
      if (questionData.questionType === "3") {
        if (!questionData.questionAnswerMinSize) {
          this.$message.error(t("v210831.please-enter-the-min-short"));
          return false;
        }
      }
      if (!questionData.questionScore) {
        this.$message.error(t("v210831.please-enter-the-score"));
        return false;
      }
    }
    if (Number(this.examPaperData.passScore) > Number(this.examPaperData.totalScore)) {
      this.$message.error(t("v210831.the-passing-score-all"));
      return false;
    }
    return true;
  }
  async init() {
    this.showAreaSelect = true;
    this.getDictionaryList("question_type").then(data => {
      this.setQuestionTypeList(data);
    });
    this.queryEnableLevelList({ pageNum: 1, pageSize: 999 }).then(data => {
      this.certLevelList = data.data.list || [];
    });

    if (!this.isAdd || this.$route.query.id) {
      this.queryExamPaperDetail({
        examPaperTempCode: this.id || this.$route.query.id
      }).then(data => {
        let questionList = data.data.contentDetail;
        data.data.examBeginTime = dayjs(data.data.examBeginTime).format("YYYYMMDDHHmmss");
        data.data.examEndTime = dayjs(data.data.examEndTime).format("YYYYMMDDHHmmss");
        questionList = questionList.map(question => {
          question.isMustAnswer = question.questionCompulsory === "1";
          question.isContainAttachment = question.containAttachment === "1";
          question.questionOption = question.questionOption.map(option => {
            option.checked = option.optionType === "1";
            return option;
          });
          return question;
        });
        this.defaultMiniImageList = [{ url: data.data.minImage }];
        this.examPaperData = data.data;
      });
    }
  }
  handleQuestionTypeChange(type, index) {
    console.log(type, index);
    this.definitionQuestionData.questionOption = [];
    this.examPaperData.contentDetail.splice(
      index,
      1,
      Object.assign({}, this.definitionQuestionData, {
        questionType: type
      })
    );
  }
  handleCommand(c) {
    switch (c) {
      case "single":
        this.examPaperData.contentDetail.push(
          Object.assign({}, this.definitionQuestionData, { questionOption: [], questionType: "1" })
        );
        break;
      case "multiple":
        this.examPaperData.contentDetail.push(
          Object.assign({}, this.definitionQuestionData, { questionOption: [], questionType: "2" })
        );
        break;
      case "short":
        this.examPaperData.contentDetail.push(
          Object.assign({}, this.definitionQuestionData, { questionOption: [], questionType: "3" })
        );
        break;
      default:
        break;
    }
  }
  async excuSaveExamPaper() {
    this.btnStartLoading();
    this.saveExamPaper(this.examPaperData)
      .then(data => {
        this.$message.success(t("v210831.saved-successfully"));
        this.$router.push(`/learning/exam/`);
      })
      .finally(data => {
        this.btnStopLoading();
      });
  }
  handleRemoveQuestion(i) {
    this.examPaperData.contentDetail.splice(i, 1);
    this.handleComputedTotalScore();
  }
  handleQuestionOrderUp(i) {
    if (i < 1) return;
    let temp = this.examPaperData.contentDetail[i];
    this.examPaperData.contentDetail[i] = this.examPaperData.contentDetail[i - 1];
    this.examPaperData.contentDetail.splice(i - 1, 1, temp);
  }
  handleQuestionOrderDown(i) {
    if (i >= this.examPaperData.contentDetail.length - 1) return;
    let temp = this.examPaperData.contentDetail[i];
    this.examPaperData.contentDetail[i] = this.examPaperData.contentDetail[i + 1];
    this.examPaperData.contentDetail.splice(i + 1, 1, temp);
  }
}
